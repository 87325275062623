@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans:400,700&display=swap');
$calfont:  'Open Sans', sans-serif;


.react-calendar__tile {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  aspect-ratio: 1/1;
  font-family: $calfont;
  font-size: 1.2em;
  font-weight: 500;
}
.react-calendar__tile--active, .react-calendar__tile--now {
  background: none  !important;
}

.react-calendar__tile--active abbr {
  background: #5582de  !important;
  color: white;
  max-width: 40px;
  max-height: 40px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding-top: 10px;
}

.react-calendar__tile--now abbr {
  background: white;
  border: 2px solid #ffb100 !important;
  max-width: 40px;
  max-height: 40px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding-top: 10px;
}

.react-calendar__tile div {
  color: #5582de;
  font-size: 2.2em;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

// .react-calendar__tile--active div {
//   color: white;

// }

.react-calendar__navigation__label {
  background-color: #e5e5e5 !important;
  font-weight: 800;
  font-size: 1.2em;
  color: black;
  font-family: "Montserrat", sans-serif;
}



.react-calendar__navigation{
  background-color: #e5e5e5 ;

}

@media only screen 
   and (max-width : 700px) {
    .react-calendar__tile--active {
  background: #5582de  !important;
}
   .react-calendar__tile--active abbr {
  background: none !important;
  color: white;
          max-width: fit-content;
        max-height: fit-content;
        width: auto;
        height: auto;
  border-radius: 0;
  padding-top: 0;
}
.react-calendar__tile--now {
  background: white;
  border: 2px solid #ffb100 !important;

}
.react-calendar__tile--now abbr {
  background: white;
  border: 0px solid #fff !important;
          max-width: fit-content;
        max-height: fit-content;
        width: auto;
        height: auto;
  border-radius: 0;
  padding-top: 0;
}
.react-calendar__tile--active div {
  color: white;

}
}