.segmented-buttons {
  width: 100%;
  display: flex;
  border: 1px solid #000;
  color: #000;
  border-radius: 999px;
  overflow: hidden;
  position: relative;
  flex-direction: row;

  .segment {
    flex: 1 1;
    display: flex;
    height: 100%;
    border-right: 1px solid #000;
    align-items: center;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    padding:5px;

    &:last-child {
      border: 0;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }

    .active {
      background: #000;
      color: #FFF;

      &:hover {
        background: #111;
      }
    }
  }
}