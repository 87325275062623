$border-color: #DDD;
$offset-background: #EFEFEF;
$gasAppBlue: #5582de;

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.content-body {
  width: 100%;
  background: #FFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 10px 10px 60px 10px;
  float: left;
  box-sizing: border-box;

  .ga-button {
    margin: 0;
  }
}

.content-block {
  display: flex;
  box-sizing: border-box;

  .equal-block {
    flex: 1 1;
    padding: 10px;
    box-sizing: border-box;
  }

  .border-line {
    width: 1px;
    background: #777;
    display: block;
  }
}

.url-block {
  border: 1px solid $border-color;
  background: $offset-background;
  color: #333;
  text-align: center;
  padding: 9px;
  font-family: monospace;
  cursor: text;
  display: inline-block;
}

.url-copy {
  display: inline-block;
  border:1px solid $border-color;
  color: #333;
  text-align: center;
  padding:7px;
  font-weight: bold;
  cursor: pointer;
  background-color: #E3E3E3;
  margin-left:5px;
  font-size: small;

  &:hover {
    background-color: $offset-background;
  }
}

.form-control {
  width: 100%;
  padding: 7px 12px;
  border: 1px solid $border-color;
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  font-size: medium;

}

.form-block {
  width: 100%;
  display: block;
  margin-top: 10px;
  text-align: left;

  &.narrow {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  label {
    font-weight: bold;
    padding: 5px;
    display: block;
  }

  small {
    padding: 0 5px 5px 5px;
    display: block;
  }

}

.image-selector {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;

  .preview-container {
    flex: 1 1;
    position: relative;
    display: block;

    .preview-image {
      width: 100%;
      padding-top: 56.25%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 20px;
      border: 1px solid $border-color;
      background-color: $offset-background;

      &.contain {
        background-size: contain;
      }
    }
  }

  .selections {
    display: block;
    margin-left: 20px;

    .selection-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;

      .selection-image {
        width: 80px;
        height: 80px;
        display: inline-block;
        border: 1px solid $border-color;
        overflow: hidden;
        border-radius: 10px;
        background-color: $offset-background;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin: 5px;

        &.active {
          border-color: #ffb100;
        }
      }
    }

    .or-label {
      display: flex;
      flex-direction: row;
      align-items: center;

      .line {
        flex: 1 1;
        height: 1px;
        background: $border-color;
        display: block;
      }

      .text {
        font-size: small;
        font-weight: bold;
        font-style: italic;
        padding: 10px;
      }
    }

    .image-upload {
      display: block;
      text-align: center;

      label {
        width: auto;
        display: block;
        font-weight: bold;
      }

      small {
        display: block;
        max-width: 250px;
        margin: 10px auto 0 auto;
        font-size: x-small;
      }
    }
  }
}

.editor-toolbar {
  border-color: $border-color;
}

.editor-body {
  min-height: 200px;
  border: 1px solid $border-color;
}

.square-selections {
  .selection-row {
    display: block;
    text-align: center;

    .item {
      width: 20%;
      padding: 10px;
      border: 1px solid rgba(0, 0, 0, 0);
      cursor: pointer;
      display: inline-block;
      border-radius: 5px;

      .square-image {
        width: 60%;
        padding-top: 60%;
        display: block;
        margin: auto;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        .alt {
          background-size: cover;
          border-radius: 10px;
        }
      }

      .consistent-title {
        min-height: 2.4em;
        margin-top: 10px;
      }

      &:hover {
        border-color: $border-color;
      }
    }
  }
}

.image-dragger {
  width: 100%;
  display: block;
  position: relative;
  border: 1px solid $border-color;
  background-color: $offset-background;
  padding: 20px;
  box-sizing: border-box;

  .empty-message {
    text-align: center;
    font-size: medium;
    font-weight: bold;
    font-style: italic;
    padding-bottom: 10px;
  }

  small {
    display: block;
  }

  .star {
    color: #ffb100;
  }

  .upload-info {
    max-width: 300px;
    margin: auto;
    font-size: xx-small;
    margin-top: 5px;
  }

  .feature-info {
    border: 1px solid $border-color;
    background-color: #FEFEFE;
    border-radius: 10px;
    padding: 5px;
    display: inline-block;
  }

  .image {
    width: 150px;
    height: 150px;
    border: 1px solid #333;
    background-color: #cfcfcf;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 5px;
    display: inline-block;
    position: relative;

    .action {
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.6);
      color: white;
      font-weight: bold;
      border-radius: 0 0 5px 0;
      padding: 2px 5px;

      .star {
        color: #ffb100;
        padding: 0 5px;
      }
    }

    .remove {
      display: inline-block;
      position: absolute;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 5px 0 0 0;
      padding: 2px 5px;

      .circle {
        width: 20px;
        height: 20px;
        color: white;
        background-color: #CC0000;
        border-radius: 50%;
        cursor: pointer;
        display: block;
        font-size: small;
        text-align: center;
        font-weight: bold;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        &:hover {
          background-color: #EE0000;
        }
      }
    }

    .dragged-over {
      border-left: 4px solid #ffb100;
    }

    .dragging {
      opacity: 0.6;
    }
  }

}

.file-hide {
  width: 1px;
  height: 1px;
  overflow: hidden;
  left: -9999px;
  top: -9999px;
  position: absolute;
}

.well {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $border-color;
  background-color: $offset-background;
  padding: 10px;
}

.progress-bar {
  width: 400px;
  height: 15px;
  border: 1px solid #000;
  position: relative;
  box-sizing: border-box;
  display: block;
  margin: auto;

  .progress {
    height: 20px;
    background: #000;
    display: block;
  }
}

.feedback-message {
  width: 100%;
  height: auto;
  padding: 10px;
  color: black;
  font-weight: bold;
  text-align: center;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 20px 0;

  a {
    color: #000;

    &:hover {
      color: #222;
    }
  }

  &.success {
    border: 1px solid rgba(154, 234, 148, 0.94);
    background-color: rgba(232, 255, 233, 0.94)
  }

  &.error {
    border: 1px solid #CC0000;
    background-color: rgba(255, 167, 170, 1);
  }
}

.website-reviews {
  box-sizing: border-box;
  position: relative;
  text-align: left;

  .empty-message {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .review-item {
    padding: 20px;
    background: #FFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin: 20px;
    display: block;
    box-sizing: border-box;
    border-radius: 5px;

    .info-area {
      display: flex;

      .half-block {
        flex: 1 1;
      }
    }

    .review-body {
      font-size: medium;
      border: 1px solid $border-color;
      margin: 10px 0;
      padding: 10px;
      border-radius: 5px;
    }

    .action-area {
      .ga-button {
        margin-right: 20px;

        &.ga-button-danger {
          background-color: #CC0000;
        }
      }
    }
  }
}

.segmented-button-container {
  width: 40%;
  margin: 10px auto;
}

.segmented-buttons {
  border-color: $gasAppBlue !important;
  border-width: 2px !important;
  color: $gasAppBlue !important;

  .segment {
    border-color: $gasAppBlue !important;
    border-width: 2px !important;

    &.active {
      background-color: $gasAppBlue !important;
      color: white !important;
    }
  }
}

.pager {
  display: block;

  .page-item {
    display: inline-block;
    padding: 5px;
    text-decoration: underline;
    cursor: pointer;

    &.selected {
      text-decoration: none;
    }
  }
}

.toast-message {
  width: auto;
  display: block;
  margin: 0 auto 40px auto;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;

  .text {
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    font-weight: bold;
    padding: 5px 12px;
    border-radius: 999px;
  }

}

.subscription-info {
  display: block;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  box-sizing: border-box;
  text-align: left;

  .sub-title {
    font-size: x-large;
    font-weight: bold;
    display: block;
  }

  .sub-info-item {
    display: block;
    margin-top: 5px;
  }

  .sub-badge {
    padding: 2px 8px;
    background-color: #DDD;
    color: #000;
    border-radius: 999px;
    font-size: small;
    font-weight: bold;

    &.success {
      background-color: #519e3f;
      color: #FFF;
    }

    &.danger {
      background-color: #CC0000;
      color: #FFF;
    }
  }

  .sub-actions {
    text-align: right;

    .ga-button {
      &.danger {
        background-color: #CC0000;

        &:hover {
          background-color: #AA0000;
        }
      }
    }
  }
}

.autocomplete-container {
  position: relative;

  .autocomplete-menu {
    display: block;
    background: #FFF;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    overflow: hidden;
    box-sizing: border-box;

    .autocomplete-item {
      display: block;
      padding: 8px 14px;
      cursor: pointer;
      border-bottom: 1px solid #DDD;

      &:hover {
        background: #EFEFEF;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.section-with-icon {
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    display:block;

    .default-icon {
      width:30px;
    }
  }

  .content {
    flex: 1 1;
    margin-left:10px;
  }
}

.website-review-counter {
  min-width: 2.1em;
  display: inline-block;
  border:2px solid $gasAppBlue;
  font-size:xx-large;
  font-weight:bold;
  color: $gasAppBlue;
  text-align:center;

  .count {
    display:block;
  }

  .label {
    display: block;
    font-size: small;
    background : $gasAppBlue;
    color: #FFF;
    text-transform: uppercase;
    padding:5px;
  }
}