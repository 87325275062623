$main-blue: #5582de;

.ga-loading-spinner {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background-color: $main-blue;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;

  &.small {
    width: 45px;
    height: 45px;

    .spinny {
      width: 35px;
      height: 35px;
    }
  }

  .spinny {
    width: 55px;
    height: 55px;
    border: 5px solid $main-blue;
    border-top: 5px solid #FFF;
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}