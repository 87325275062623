$gasblue: #5582de;
$textgrey: #4a4a4a;

.what-you-get {
  h2 {
    text-align: center;
    margin-top: 48px;
    margin-bottom: 24px;
    color: $textgrey;
    font-weight: 800;
  }

  h3 {
    font-size: 1.9375rem;
  }

  p {
    text-align: center;
  }

  .medium-6 {
    margin: 0 auto;

    .tick-wrap {
      display: flex;
      flex-direction: row;
      flex: 2;

      img {
        width: 18px;
        height: 14px;
        display: inline-block;
        margin-right: 24px;
        margin-left: 36px;
        margin-top: 6px;
      }

      .tick-text {
        margin-bottom: 36px;

        .tick-title {
          min-height: 2.8em;
          display: block;
          font-weight: 800;
          text-align: left;
        }

        .tick-note {
          display: block;
          font-weight: 400;
        }
      }
    }
  }
}

.what-its-on {
  h2 {
    color: $textgrey;
    font-size: 24px;
    text-align: center;
    margin-top: 48px;
    margin-bottom: 24px;
  }

  img {
    width: 100%;
    max-width: 600px;
    display: block;
    margin: 0 auto;
  }
}

.how-much {
  p {
    text-align: center;
  }

  .medium-6 {
    border: 2px solid #363636;
    border-radius: 10px;
    padding: 12px;
    margin-bottom: 12px;

    h2 {
      text-align: center;
      color: $textgrey;
      font-size: 36px;
      margin-top: 16px;
    }

    .price {
      font-size: 54px;
      text-align: center;
      color: $textgrey;
      margin-top: 20px;
      margin-bottom: -8px;

      span {
        font-size: 30px;
      }

      &:after {
        content: '.00';
        font-size: 30px;
      }
    }

    .time {
      font-size: 18px;
      text-align: center;
      display: block;
      width: 100%;
      color: $textgrey;
    }

    .note {
      font-size: 18px;
      color: #707070;
      text-align: center;
      display: block;
      width: 100%;
    }

    .btn-wrap {
      width: 100%;
      display: block;
      padding: 0 24px;

      .button {
        width: 100%;
        margin-top: 47px;

        .trial-note {
          display: block;
          text-align: center;
          font-weight: 400;
          font-size: 14px;
          margin-top: 6px;
        }
      }
    }

    &.monthly {

    }

    &.yearly {
      background-color: #e4ecfa;
      border: 2px solid #5d687d;

      .btn-wrap {
        .button {
          background-color: #76d984;
          color: #fff;
          margin-top: 20px;
        }
      }
    }
  }

  .stripe {
    display: flex;
    margin: 0 auto;
    flex-direction: row;
    flex: 4;
    width: 100%;
    justify-content: center;
    margin-top: 12px;
    margin-bottom: 24px;

    img {
      height: 40px;
      display: inline-block;
      margin: 0 6px;
    }
  }
}


// websites carousel

.slideshow-container {
  width: 100%;
  position: relative;
  margin: auto;
  margin-top: 48px;
  float: left;
}

/* Hide the images by default */
.slide {
  img {
    width: 100%;
  }
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 24px;
  transition: 0.3s ease;
  // border-radius: 0 3px 3px 0;
  border-radius: 3px;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.prev {
  left: 0;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  // border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: $gasblue;
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  background-color: rgba(0, 0, 0, 0.7);
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.slide-dots {
  text-align: center;
  margin-bottom: 36px;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: .4
  }
  to {
    opacity: 1
  }
}

@keyframes fade {
  from {
    opacity: .4
  }
  to {
    opacity: 1
  }
}

.sign-up-box {
  // border:1px solid #ccc;
  border-radius: 10px;
  padding: 24px;
  background-color: $gasblue;
  margin-bottom: 36px;
  float: none !important;

  h3, p {
    color: #fff !important;
  }

  h3 {
    font-weight: bold;
  }

  .ga-button {
    background-color: #ffb100;
    color: #000;
    text-decoration: none;
    margin-bottom: 30px;

    &:hover {
      background-color: #e19106
    }
  }

  a:link {
    color: white;
  }

  a:visited {
    color: white;
  }
}

.websites-header {
  // margin-bottom:12px;
}

.what-you-get {
  h3 {
    margin-bottom: 36px;
    font-weight: bold;
    color: $textgrey;
  }
}


@media only screen and (max-width: 1023px) {


  .top-bar {
    background: rgba(0, 0, 0, 0.8);
    display: block;
    position: fixed;
    z-index: 99;
    width: 100%;
    padding: 0px;

    div {
      .menu {
        background: none;
      }
    }

    .top-bar-left {
      display: none;
    }

    .top-bar-right {
      .menu {
        display: inline-block;
        width: 100%;

        li {
          padding: 10px 0;
        }

        li:not(:last-child) {
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }

        li:hover {
          background: #000;
        }
      }
    }
  }

  .main-header {
    .top-header {
      .header-right {
        height: auto;
      }
    }

    .header-text {
      font-size: 18px;
      line-height: 30px;
    }
  }
  .testi {
    // -webkit-column-count: 2;
    // -moz-column-count:2;
    // column-count:2;
  }
  // .no-js .top-bar {
  //    display: block;
  //  }

  //  .no-js .title-bar {
  //    display: none;
  //  }

}

.contact-submitted {
  li {
    color: $textgrey;
  }
}

.g-recaptcha {
  margin-bottom: 12px;
}

.g-recaptcha > div {
  margin: 0 auto;
}

.sponsor-form {
  max-width: 350px;
  margin: auto;
  color: white;

  label {
    font-weight: bold;
  }

  .form-block {
    margin-top: 10px;
  }

  .text-center {
    text-align: center;
  }

  .bordered-content {
    border: 2px solid #FFF;
    padding: 5px;
    border-radius: 10px;
    margin-top:20px;

    .top-content {
      font-size:small;
      display:block;
    }
  }

}


@media only screen and (max-width: 639px) {

  p {
    font-size: 14px;
  }

  h1 {
    font-size: 36px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .main-header {
    .top-header {
      .header-left {
        height: 320px;
      }

      .header-right {
        height: auto;
        max-height: none;
        min-height: 0;

        img {
          width: 100%;
          // display:block;
          // margin:0 auto;
        }

        p {
          margin-top: 28px;
        }
      }
    }

    .spacer {
      display: none;
    }

    .header-text {
      font-size: 14px;
      line-height: 24px;
    }

    .bottom-header {
      margin-top: 0px;
      padding: 0;
      height: auto;

      h1 {
        text-align: center;
        margin: 24px;
      }
    }
  }

  .features {
    .cell {
      img {
        height: 120px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .grey-seg {
    h1 {
      font-size: 36px;
    }
  }

  .footer {
    .cell {
      margin-bottom: 24px;
    }

    .other-footer {
      span {
        text-align: left;
      }

      .company {
        li {
          text-align: left;
        }
      }

      .social {
        float: left;
      }
    }
  }

  .ptier {
    margin-bottom: 24px;
  }

  .faq {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
  .download {
    a {
      margin-bottom: 24px;
    }

    .medium-5 {
      img {
        display: inline-block;
        margin: 0 auto;
      }
    }

    h2 {
      text-align: center;
      font-size: 18px;
    }
  }
  .testi {
    // -webkit-column-count: 1;
    // -moz-column-count:1;
    // column-count:1;
    .testimonial {
      display: inline-block;
      width: 100%;

      img {
        display: block;
        margin: 0 auto;
      }
    }
  }
  .lenders {
    h2 {
      font-size: 30px;
    }
  }

  .pricing {
    border: 2px solid #ccc;
    border-radius: 15px;
    padding: 16px;
    margin: 12px;

    .tier-features {
      min-height: 0;
    }
  }

  .what-you-get {
    h2 {
      font-size: 30px;
    }
  }

}
