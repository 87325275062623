.simple-alert {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: none;
  align-items: center;
  z-index: 9999;

  &.open {
    display: flex;
  }

  .alert-padding {
    flex: 1 1;
  }

  .alert-modal {
    max-width: 250px;
    background: #FFF;
    color: black;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;

    .modal-title {
      font-weight: bold;
      display: block;
      font-size: large;
    }

    .modal-message {
      display: block;
      margin: 5px 0 15px 0;
    }

    .modal-button {
      display: block;
      margin-top: 5px;
      border-radius: 999px;
      background-color: #DDD;
      font-weight: bold;
      padding: 10px;
      cursor: pointer;

      &:hover {
        background-color: #EEE;
      }

      &.success {
        background-color: #519e3f;
        color: white;

        &:hover {
          background-color: #68c153;
        }
      }

      &.danger {
        background-color: #CC0000;
        color: white;

        &:hover {
          background-color: #EE0000;
        }
      }
    }
  }
}