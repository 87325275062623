// imports

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans:400,700&display=swap');

// end of imports


// Variables

$main-grey: #ccc;
$light-grey: #999;
$inbetween-grey: #bbb;
$dark-grey: #555;
$main-blue: #5582de;
$main-yellow: #ffb100;
$button-green: #86C46A;
$button-red: #D85946;
$button-green-hover: #519e3f;

$head-font: 'Montserrat', sans-serif;
$body-font: 'Open Sans', sans-serif;

$base-shad: 0px 2px 4px rgba(0,0,0,0.2);
$hover-shad: 0px 4px 6px rgba(0,0,0,0.1);

$grid-columns:(1,2,3,4,5,6,7,8,9,10,11,12);
$sections:(office,breaktime,essentials,websites);

// end of variables




// basic grid styles

.App {
    background-color:#fff;
}
.main-wrap {
    width:100%;
    max-width:1280px;
    margin:0 auto;
    display:block;
    font-family:$body-font;
    .cell {
        display:inline-block;
        box-sizing:border-box;
        vertical-align:top;
        float:left;
      padding:15px;
        &.no-pad {
            padding:0;
        }
    }   
}

@each $size in $grid-columns {  // small = 0px to 639px
    .small-#{$size} {width:calc( (100% / 12) * #{$size} );}
}
@media screen and (min-width: 640px) {
    @each $size in $grid-columns {  // medium = 640px to 1279px
        .medium-#{$size} {width:calc( (100% / 12) * #{$size} );}
    }
}
@media screen and (min-width: 1280px) {
    @each $size in $grid-columns {  // large = 1280px and above
        .large-#{$size} {width:calc( (100% / 12) * #{$size} );}
    }
}

// end of basic grid styles






body {
    background-color:white;
}

p, span, s, li, textarea {
    font-family: $body-font;
}
h1, h2, h3, h4, h5, h6 {
    font-family: $head-font;
}
iframe {
    border:0;
}
// ::-webkit-scrollbar {
//     height: 0px;  /* Remove scrollbar space */
//     width:0;
//     background: transparent;  /* Optional: just make scrollbar invisible */
// }
.top-space {
    height:106px;
    margin-bottom:12px;
    &.smaller-space {
        height:54px;
    }
    .navbar {
        background-color: #5582de;
        color: #ffffff;
        position:fixed;
        width:100%;
        z-index:2;
        // border-bottom:1px solid rgba(0,0,0,0.05);

      .countdown {
        font-weight:700;
        font-size: 16px;
        background-color:#325bae;
        padding:6px 10px;
        position:fixed;
        bottom:0;
        left:0;
        border-radius:0px 8px 0px 0px;
      }

      .settings {
        float: right;
        width: 30px;
        height: 30px;
        background: url("../img/profile.svg") no-repeat center;
        margin: 18px 15px 0px 0px;
        cursor:pointer;
        opacity:1;
        &:hover {
          opacity:0.8;
        }
      }
      .logout {
        float: right;
        height: 30px;
        margin: 18px 30px 0px 0px;
        cursor:pointer;
        opacity:1;
        &:hover {
          opacity:0.8;
        }
      }
        .nav-wrap {
            width:100%;
            overflow-x:auto;
            overflow-y:hidden;
            // border-top:1px solid rgba(255,255,255,0.1);
            &::-webkit-scrollbar {
                height: 0px;  /* Remove scrollbar space */
                width:0;
                background: transparent;  /* Optional: just make scrollbar invisible */
            }
            .nav-box {
                // width:1400px;
                // margin:0 auto;
            }
        }
    }
}


.logo {
  // width: 180px;
  height: 42px;
  background: url('../img/gasapp-logo-large.png');
  background-repeat:no-repeat;
  background-position:20px center;
  background-size:contain;
  margin: 0 auto;
  margin-top:12px;
  margin-bottom:12px;
}
.premium-logo {
   // width: 180px;
   height: 42px;
   background: url('../img/gas_app_prem_logo.png');
   background-repeat:no-repeat;
   background-position:20px center;
   background-size:contain;
   margin: 0 auto;
   margin-top:12px;
   margin-bottom:12px;
 }
.big-logo {
    background: url('../img/gasapp-logo-large.png');
    background-repeat:no-repeat;
    height:120px;
    background-size:contain;
    background-position:center center;
}



.menu-icon {
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-right: 7px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}


.like-icon {
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-right: 7px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  vertical-align:-3px;
}
.full-like {
  background-image: url("../img/like-filled.png");

}
.empty-like {
  background-image: url("../img/like-empty.png");
}
.likespan {

  margin-right: 20px;
}

.comment-icon {
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-right: 7px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  vertical-align:-3px;

}
.full-comment {
  background-image: url("../img/comment-filled.png");

}
.empty-comment {
  background-image: url("../img/comment-empty.png");
}
.edit-comment {
  background-image: url("../img/edit.png");
}

.report-comment {
  background-image: url("../img/report.png");
}

.crimso {
  color: crimson;
}

.accept {
  background-image: url("../img/accept_answer.png");
}



.reply-label {
    background:#eee;
    padding:6px;
    text-align:left;
    font-size:12px;
    font-weight:700;
    box-shadow: $base-shad;
}

.acceptBanner{
  background: #dbf2d5;
  padding:12px;
  box-shadow: $base-shad;
}


.pdf-icon {
  display: inline-block;
  width: 37px;
  height: 37px;
  margin-right: 7px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  background-image: url("../img/leafletspdf.png");
}





.bell-icon {
  display: inline-block;
  width: 28px;
  height: 28px;
  // margin-right: 7px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  float: right;
}
.notif-full {
  background-image: url("../img/sub2.png");

}
.notif-empty {
  background-image: url("../img/unsub2.png");
}

.menu-icon-settings {
  background-image: url("../img/profile.svg");
}

.menu-icon-office {
  background-image: url("../img/menu_item_office.svg");
}

.menu-icon-breaktime {
    background-image: url("../img/menu_item_breaktime.svg");
}

.menu-icon-websites {
  background-image : url("../img/menu_item_websites.svg");
}
.disclosure {
    float: right;
    // height: 90px;
    height:28px;
    width: 15px;
    margin-right: 15px;
    background: url("../img/disc1.png") no-repeat center;
}

.icon-reference {
    background: url("../img/book1.svg") no-repeat center;
}
.icon-vid {
    background: url("../img/videos.svg") no-repeat center;
}
.icon-tap {
    background: url("../img/tap.svg") no-repeat center;
}
.icon-phone {
    background: url("../img/phone1.svg") no-repeat center;
}
.icon-customers {
    background: url("../img/customers.png") no-repeat center;
}
.icon-bell {
    background: url("../img/reminders.png") no-repeat center;
}

.icon-new-invoice {
  background: url("../img/ic_create_invoice.png") no-repeat center;
}
.icon-reminders {
  background: url("../img/ic_reminders.png") no-repeat center;
}
.icon-calendar {
  background: url("../img/ic_calendar.png") no-repeat center;
}
.icon-view-invoice {
  background: url("../img/ic_view_invoice.png") no-repeat center;
}


.icon-view-quote {
  background: url("../img/ic_view_quote.png") no-repeat center;
}


.icon-new-quote {
  background: url("../img/ic_create_quote.png") no-repeat center;
}


.icon-prev-forms {
  background: url("../img/ic_create_quote.png") no-repeat center;
}


.icon-customers {
  background: url("../img/ic_create_quote.png") no-repeat center;
}



.post-header-icon {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-right: 7px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}


.isInstaller {
  background: url("../img/large-installations.png") no-repeat center;
}
.isBreakdown {
  background: url("../img/large-repairs-and-servicing.png") no-repeat center;
}
.isMisc {
  background: url("../img/large-misc.png") no-repeat center;
}
.isApprentice {
  background: url("../img/large-apprentice.png") no-repeat center;
}
.isTrainingCentreUser {
  background: url("../img/train.png") no-repeat center;
}
.hasValidGasCard {
  background: url("../img/valid.png") no-repeat center;
}



.placeholder-icon {
  display: inline-block;
  width: 54px;
  height: 54px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  background-image: url("../img/image_placeholder.png");
  margin-right:12px;

}


.main-wrap {
    .cell.header {
        padding:12px 15px 6px 15px;
        text-align:left;
        position: relative;
        .head-title {
            text-align:left;
            margin:0;
            color:$dark-grey;
            display:inline-block;
            vertical-align:middle;
            margin-right:24px;
            &:before {
                content:"";
                display:inline-block;
                height:32px;
                width:32px;
                filter:invert(66.666%);
                background-repeat:no-repeat;
                background-position:center center;
                background-size:contain;
                vertical-align:-4px;
                margin-right:8px;
            }
        }
    }
}



// sass function for each icon once they have been converted to svg
@each $name in $sections {
    .header {
        .head-title {
            &.#{$name} {
                &:before {
                    background-image:url('../img/menu_item_#{$name}.svg');
                }
            }
        }
    }
}


.water-hard {
    height: 128px;
    width: 128px;
    display: inline-block;
    background: url("../img/red-128.png") no-repeat center;
}
.water-medium {
    height: 128px;
    width: 128px;
    display: inline-block;
    background: url("../img/amber-128.png") no-repeat center;
}
.water-soft {
    height: 128px;
    width: 128px;
    display: inline-block;
    background: url("../img/green-128.png") no-repeat center;
}

.nav-box {
    a {
        // border-right:1px solid rgba(255,255,255,0.5);
        .menuItem {
            display: inline-block;
            height: 25px;
            padding: 10px 16px 4px 16px;
            cursor:pointer;
            border-bottom:4px solid rgba(255,255,255,0);
            transition:border 0.2s ease;
            &:hover {
                // border-bottom:4px solid rgba(255,255,255,0.5);
                border-bottom:4px solid $main-yellow;
                span {
                    color:rgba(255,255,255,0.6);
                }
            }
            .menu-item {
                vertical-align:2px;
                color:rgba(255,255,255,1);
            }
            span {
                vertical-align:2px;
                color:rgba(255,255,255,1);
            }
        }
        &:last-child {
            border-right:0;
        }
    }
}
// .menuItem {
//     display: inline-block;
//     height: 25px;
//     padding: 10px 16px 0 16px;
//     cursor:pointer;
//     border-bottom:4px solid rgba(255,255,255,0);
//     transition:border 0.2s ease;
//     border-right:1px solid rgba(255,255,255,0.5);
//     &:hover {
//         // border-bottom:4px solid rgba(255,255,255,0.5);
//         border-bottom:4px solid $main-yellow;
//         span {
//             color:rgba(255,255,255,0.6);
//         }
//     }
//     &:last-child {
//         border-right:1px solid rgba(255,255,255,0.5);
//     }
//     .menu-item {
//         vertical-align:2px;
//         color:rgba(255,255,255,1);
//     }
//     span {
//         vertical-align:2px;
//         color:rgba(255,255,255,1);
//     }
// }

// .sectionIcon {
//     float: left;
//     display: inline-block;
//     height: 90px;
//     width: 90px;
// }





.menu-divider {
    display: inline-block;
    width: 1px;
    height: 25px;
    background-color: #ffffff;
    margin: 0 8px;
}


.grey-main {

    background-color:$main-grey;
    margin: 0 15px;
    padding: 15px;
    color: #000000;
}

.rounded-main {
    // border-radius: 15px;
    border-radius:6px;
    // background-color: #5582de;
    background-color:$dark-grey;
    // margin: 15px;
    padding: 6px;
    color: #fff;
    h2 {
        text-transform:uppercase;
        margin-bottom:12px;
    }
}

.rounded-white {
    // border-radius: 15px;
    padding: 15px;
    border-radius:6px;
    background-color: #fff;
    color: #000;
    box-shadow:$base-shad;
}

.ref-sch {
    .title-text {
        font-weight:700;
        font-size:20px;
        text-transform:uppercase;
        font-family:$head-font;
        margin-bottom:12px;
        border-bottom:1px solid #ccc;
        padding-bottom:12px;
    }
    .body-text {
        font-size:14px;
        line-height:24px;
    }
    .code {
        border:1px solid #bbb;
        padding:12px 16px;
        box-shadow:inset 0px 0px 6px rgba(0,0,0,0.2);
        border-radius:4px;
        display:inline-block;
        margin:16px auto;
        font-size:18px;
        // margin-top:12px;
        // margin-bottom:12px;
    }
    .points {
        background-color:$main-blue;
        display:inline-block;
        padding:12px 16px;
        margin:12px auto;
        color:#fff;
        box-shadow:inset 0px 0px 6px rgba(0,0,0,0.2);
        cursor:default;
        font-size:14px;
        font-weight:700;
        span {
            font-weight:700;
            font-size:20px;
        }
    }
}

.square-white {
    padding: 15px;
    // margin: 15px;
    background-color: #fff;
    color: #000;
    cursor:pointer;
    transition:all 0.2s ease;
    box-shadow: $base-shad;
    & > * {
        vertical-align:middle;
        box-sizing:border-box;
    }
    .part-thumb {
        width:40%;
        display:inline-block;
        img {
            width:100%;
        }
        padding:15px;
    }
    .part-desc {
        width:60%;
        display:inline-block;
        p, h2 {
            text-align:left;
        }
        p {
            font-size:14px;
            line-height:24px;
        }
    }
    &:hover {
        box-shadow: $hover-shad;
        .part-desc {
            h2 {
                text-decoration: underline;
            }
        }
    }
    &.small {
        .part-thumb, .part-desc {
            display:block;
            width:100%;
        }
        .part-thumb {
            padding:0;
            img {
                width:50%;
            }
        }
        .part-desc {
            h2 {
                width:100%;
                text-align:center;
            }
        }
    }
}

.list-item {
    background-color:#fff;
    padding:15px;
    cursor:pointer;
    margin-bottom:12px;
    text-align:left;
    transition:all 0.2s ease;
    // box-shadow: $base-shad;
    border:1px solid #c7c7c7;
    border-radius:5px;
    box-sizing:border-box;
    > * {
        cursor:pointer;
    }
    h4 {
        margin-top:0;
        margin-bottom:10px;
    }
    span {
        display:block;
    }
    .sectionIcon {
        // float: left;
        display: inline-block;
        height: 90px;
        width: 90px;
        vertical-align:middle;
    }
    .section-title {
        display: inline-block;
        // height: 90px;
        // line-height: 90px;
        font-size: 1.4em;
        vertical-align:middle;
        padding-left:24px;
        transition:all 0.2s ease;
        font-family: $body-font;
    }
    .news-date {
        font-size:12px;
        font-weight:700;
        color:$light-grey;
        font-family:$body-font;
    }
    .news-title {
        font-size:24px;
        font-weight:700;
        font-family:$body-font;
        margin-bottom:8px;
        padding-right:75px;
    }
    .news-desc {
        font-size:14px;
        line-height:24px;
        font-family:$body-font;
        padding-right:75px;
    }
    &:hover {
        background-color:#eee;
        box-shadow:$hover-shad;
        .section-title {
            padding-left:48px;
        }
        .news-title {
            text-decoration:underline;
        }
    }
}

.list-arrow {
    background-image: url("../img/disc1.png");
    background-repeat: no-repeat;
    background-size:15px 28px;
    background-position:96% center;
}
.main-wrap.contacts {
    .list-item {
        cursor:default;
        &:hover {
            background-color:#fff;
            box-shadow: $base-shad;
        }
        > * {
            cursor:default;
        }
        .title-text {
            display:inline-block;
            font-weight:700;
            margin-right:36px;
        }
        .phone {
            display:inline-block;
            .phone-number {
                cursor:auto;
            }
        }
    }
}

@media screen and (max-width: 639px) {
    .square-white {
        .part-thumb, .part-desc {
            display:block;
            width:100%;
        }
        .part-thumb {
            padding:0;
            img {
                width:50%;
            }
        }
        .part-desc {
            h2 {
                width:100%;
                text-align:center;
            }
        }
    }
}



.ga-button {
    padding: 12px 30px;
    display: inline-block;
    background-color: $button-green;
    font-family:$body-font;
    font-weight:600;
    color: #fff;
    cursor:pointer;
    transition:0.2s all ease;
    vertical-align:middle;
    margin-right:24px;
    border-radius: 5px;
    text-decoration:none;
    &:hover {
        background-color: $button-green-hover;
    }
}

.ga-button div {
    display: inline-block;
}
.ga-button.modal-btn {
    display:block;
    width:100%;
    margin:0;
    box-sizing:border-box;
}
.main-wrap {
    .cell.modal-btn-wrap {
    padding: 6px 0;
}
}


// .space {
//     height: 15px;
// }

.add-space {
    margin-bottom:12px;
}

// .section-title {
//     display: inline-block;
//     height: 90px;
//     line-height: 90px;
//     font-size: 1.4em;
// }


.title-text {
    font-size: 1.17em;
}
.btn-text {
    font-size: 1.17em;
}

.tnc-text {
    font-size: 0.7em;
    cursor:pointer;
    margin-top:6px;
}
.store-, .terms-frame {
    iframe {
        width:100%;
        // height:500px;
        height:calc(100vh - 162px);
    }    
}

.underline {
    text-decoration: underline;
}

// .post-ff{
//   display: inline-block;
//   // height: 210px;
//   // width: 190px;
//   width:100%;
//   // padding: 15px;
//   // margin: 15px;
//   background-color: #ffffff;
//   color: #000000;
//   box-sizing:border-box;
//   padding-bottom:8px;
//   padding-top:8px;
//   box-shadow:$base-shad;
//   margin-bottom: 16px;


//   .user-info{
//     text-align: left;
//     margin: 0px 8px 8px 8px;
//   }
//   .pdf-link{
//     text-align: left;
//     margin: 0px 8px 8px 8px;
//   }
//   .body{
//     text-align: left;
//     margin: 0px 8px 8px 8px;
//   }
//   .footer{
//     text-align: left;

//     margin: 0px 8px 8px 8px;
//   }
// }
.indent1 {
  width:97%;
  margin-left: 3%;
  // margin-right: 5%;

}
.indent2 {
  width:94%;
  margin-left: 6%;
  // margin-right: 10%;
}
.ga-button.delete {
    background-color:#D85946;
}
.yellow-border
{
  border:4px solid $main-yellow;
}
.brains {
  display: inline-block;
  width: 65px;
  height: 65px;
  // margin-right: 7px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  float: left;
  background-image: url("../img/knowledge-base.png");
}

.refresher {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 9px;
  padding-bottom: 7px
}


.bln {
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 5px;
  padding-bottom: 6px
}

.notificationsBadge {
  position: absolute;
  top: 0;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: red;
  text-align: center;
  line-height: 25px;
  color: white;
  font-size:14px;
}

.post-cell, .post-ff {
  // display: inline-block;
  // width:100%;
  background-color: #ffffff;
  color: #000000;
  box-sizing:border-box;
  padding:12px;
  box-shadow:$base-shad;
  border:1px solid #e5e5e5;
  // width:400px;
  // height:400px;
  .notif-info {
    text-align: left;
    // margin: 0px 8px 8px 8px;

    .right {
      float: right;
      width: 100px;
    }
    .left {
      float: left;
      width: 100px;
    }
    .middle {
      margin: 0 100px;
      min-height: 55px;
    }
    .prof-img {
      display: inline-block;
      img {
        width: 54px;
        height: 54px;
        object-fit: cover;
        margin-right: 12px;
      }
    }
    .msg {
      display:inline-block;
      vertical-align:top;
      padding-top:22px;

    }
    .destroy {
      float:right;
      background: #D85946;
      padding: 10px;
      font-family:$body-font;
        font-weight:600;
        color: #fff;
        cursor:pointer;
        transition:0.2s all ease;
        vertical-align:middle;
        border-radius: 5px;
        margin-top:7px;
    }
    .user-deets {
      display:inline-block;
      vertical-align:top;
      padding-top:2px;
      margin-right: 100px;

      .upper-deets {
        display:block;
        .username {
          font-weight:700;
          vertical-align:middle;
          margin-right:12px;
        }
        .post-header-icon {
          height:24px;
          width:24px;
          background-size:contain;
          vertical-align:middle;
        }
      }
      .lower-deets {
        display:block;
        padding-top:4px;
        .date-created {
          font-size:12px;
          color:#555;
        }
      }
    }
  }

  .user-info{
    text-align: left;
    // margin: 0px 8px 8px 8px;
    // border-bottom:1px solid #ddd;
    padding-bottom:8px;
    margin-bottom:8px;
    .prof-img {
      display:inline-block;
      img {
        width:54px;
        height:54px;
        object-fit:cover;
        margin-right:12px;
      }
    }

    .user-deets {
        display:inline-block;
        vertical-align:top;
        padding-top:2px;
        .upper-deets {
            display:block;
            .username {
                font-weight:700;
                vertical-align:middle;
                margin-right:12px;
            }
            .post-header-icon {
                height:24px;
                width:24px;
                background-size:contain;
                vertical-align:middle;
            }
        }
        .lower-deets {
            display:block;
            // padding-top:4px;
            .date-created {
                font-size:12px;
                color:#555;
            }
        }
    }

  }
  .pdf-link{
    text-align: left;
    margin-bottom:12px;
    text-decoration: underline;
    cursor:pointer;
    padding:8px;
    border:1px solid #eee;
    &:hover {
        background:#eee;
    }
    .pdf-icon {
        vertical-align:middle;
    }
  }
  .title {
    text-align:left;
    margin-bottom:12px;
    font-weight:700;
  }

  .body{
    text-align: left;
    // margin: 0px 8px 8px 8px;
    margin-bottom:12px;
  }
  .image-gallery {
    // background-color:#444;
    font-size:0;
    .image-thmb {
        display:inline-block;
        width:calc(50% - 8px);
        margin:4px;
        height:180px;
        object-fit:cover;
        box-sizing:border-box;
        border:1px solid #888;
        border-radius:4px;
        &:last-child:not(:nth-child(even)) {
            width:calc(100% - 8px);
        }
    }
  }
  .footer{
    text-align: left;
    // margin: 0px 8px 8px 8px;
    // border-top:1px solid #ddd;
    padding-top:12px;
    .footer-btn {
        display:inline-block;
        margin-right:24px;
        cursor:pointer;
        padding:4px 0;
        &:hover {
            text-decoration:underline;
        }
    }
  }
}
@media screen and (max-width: 1279px) {
    .post-cell {
        width:calc(50vw - 25px);
    }
}

@media screen and (max-width: 639px) {
    .post-cell {
        width:calc(100vw - 30px);
    }
}

@media screen and (min-width: 1280px) {
    .post-cell {
        width:403.333px;
    }
}



.edit-box {
    background-color:#fff;

    border-radius:5px 5px 0 0;
    border-top:1px solid #c7c7c7;
    border-left:1px solid #c7c7c7;
    border-right:1px solid #c7c7c7;
    padding:12px;
    label {
        .edit-box-label {
            font-weight:700;
            width:100%;
            display:inline-block;
            text-align:left;
            margin-bottom:4px;
            font-family:$head-font;
        }
        textarea {
            width:100%;
            border:1px solid #c7c7c7;
            // border-radius:4px;
            min-height:80px;
            font-size:16px;
            padding:10px 16px;
            box-sizing:border-box;
            resize:none;
            border-radius:5px;
        }
    }
    // .file-btn {
    //     width:0.1px;
    //     height:0.1px;
    //     opacity:0;
    //     overflow:hidden;
    //     position:absolute;
    //     z-index:-1;
    //     + label {
    //         font-size: 16px;
    //         font-weight:700;
    //         color:#fff;
    //         background-color:#000;
    //         display:inline-block;
    //         cursor:pointer;
    //         * {
    //             pointer-events: none;
    //         }
    //     }
    //     &:focus + label {
    //         outline: 1px dotted #000;
    //         outline: -webkit-focus-ring-color auto 5px;
    //     }
    //     &:focus + label, + label:hover {
    //         background-color:red;
    //     }
    // }
}
.create-box {
    > .cell {
        background-color:#fff;
        // box-shadow: $base-shad;
        h2 {
            text-align:left;
            margin:0;
            border-bottom:2px solid #ddd;
            padding-bottom:14px;
        }
        .create-label {
            font-weight:700;
            text-align:left;
            display:block;
            margin-bottom:4px;
            font-family:$head-font;
            &.center-text {
                text-align:center;
            }
        }
        .create-select {
            display:block;
            font-size:16px;
            font-family:$body-font;
            padding:10px 24px 10px 16px;
            box-sizing:border-box;
            margin:0;
            cursor:pointer;
            border:1px solid $dark-grey;
            border-radius:0;
            width:100%;
            max-width: 100%;
            -moz-appearance:none;
            -webkit-appearance:none;
            appearance:none;
            background-color:#fff;
            background-image:url('../img/drop-arrow.svg');
            background-repeat: no-repeat;
            background-position:right .7em top 50%, 0 0;
            background-size: .65em auto, 100%;
        }
        .create-title {
            display:block;
            width:100%;
            max-width:100%;
            font-size:16px;
            font-family:$body-font;
            padding:10px 16px;
            box-sizing:border-box;
            border:1px solid #c7c7c7;
            border-radius:5px;
        }
        .create-comment {
            display:block;
            width:100%;
            max-width:100%;
            height:240px;
            font-size:16px;
            font-family:$body-font;
            padding:10px 16px;
            box-sizing:border-box;
            resize:none;
            border:1px solid #c7c7c7;
            border-radius:5px;
        }
        .upload-wrap {
            input {

            }
        }
        .create-search {
            display:block;
            font-size:16px;
            font-family:$body-font;
            padding:10px 16px;
            width:100%;
            max-width:100%;
            box-sizing:border-box;
        }
    }
}

.button-group {
    background-color:#fff;
    padding:0 12px 12px;
    border-radius:0 0 5px 5px;
    border-bottom:1px solid #c7c7c7;
    border-left:1px solid #c7c7c7;
    border-right:1px solid #c7c7c7;
}
.post-cell {
    cursor:pointer;
}

.horror-overlay {
  position: absolute;
  top: 16px;
  left: 16px;
  color: #ffffff;
  font-size: 16px;
  background:rgba(0,0,0,0.4);
  padding: 10px 10px 0 10px;
}


.horror-cell {
  display: inline-block;
  // height: 210px;
  // width: 190px;
  width:100%;
  // padding: 15px;
  // margin: 15px;
  background-color: #ffffff;
  color: #000000;
  box-sizing:border-box;
  cursor:pointer;
  padding-bottom:8px;
  box-shadow:$base-shad;
  .horror-image {
    width:100%;
    height:280px;
    overflow:hidden;
    img {
      width:100%;
      height:280px;
      object-fit:cover;
    }
  }
  .horror-rating {
    margin-top:8px;
    margin-bottom:8px;
    .full-star, .empty-star {
      width:15%;
      max-width:32px;
      height:28px;
      display:inline-block;
      background-repeat:no-repeat;
      background-position:center center;
      background-size:contain;
      overflow:hidden;

    }
    .full-star {
      background-image:url('../img/full-star.svg');
    }
    .empty-star {
      background-image:url('../img/emtpy-star.svg');
    }
  }

  .name {
    font-size:16px;
  }
  .loc {
    font-size:14px;
    color:$light-grey;
  }
  &:hover {
    .name {
      text-decoration: underline;
    }
  }
}





.talent-cell {
  display: inline-block;
  // height: 210px;
  // width: 190px;
  width:100%;
  // padding: 15px;
  // margin: 15px;
  background-color: #ffffff;
  color: #000000;
  box-sizing:border-box;
  cursor:pointer;
  padding-bottom:8px;
  box-shadow:$base-shad;
  .horror-image {
    width:100%;
    height:280px;
    overflow:hidden;
    img {
      width:100%;
      height:280px;
      object-fit:cover;
    }
  }
  .talent-rating {
    margin-top:8px;
    margin-bottom:8px;
    .full-thumb, .empty-thumb {
      width:15%;
      max-width:32px;
      height:28px;
      display:inline-block;
      background-repeat:no-repeat;
      background-position:center center;
      background-size:contain;
      overflow:hidden;

    }
    .full-thumb {
      background-image:url('../img/full-thumb.svg');
    }
    .empty-thumb {
      background-image:url('../img/empty-thumb.svg');
    }
  }

  .name {
    font-size:16px;
  }
  .loc {
    font-size:14px;
    color:$light-grey;
  }
  &:hover {
    .name {
      text-decoration: underline;
    }
  }
}


.break-video {
    padding:0;
    font-size:0;
    .video-cell {
        width:100%;
        display:inline-block;
        box-sizing:border-box;
        .video-image {
            width:100%;
            height:280px;
            position:relative;
            font-size:0;
            overflow:hidden;
            img {
                background-color:#000;
                width:100%;
                height:280px;
                object-fit:cover;
            }
            .play-btn {
                background-color:rgba(0,0,0,0.5);
                background-image:url('../img/play-btn.svg');
                position:absolute;
                filter:opacity(0.6);
                top:0;
                bottom:0;
                left:0;
                right:0;
                background-repeat:no-repeat;
                background-position:center center;
                background-size: auto 35%;
                transition:all ease 0.1s;
                // opacity:0.2;
            }
        }
        .video-overlay {
            background-color:rgba(0,0,0,0.5);
            position:absolute;
            top:0;
            left:0;
            right:0;
            padding:8px;
            transition:all ease 0.1s;
            > div {
                color:#fff;
                text-align:left;
                font-size:16px;
            }
            .vid-title {
                font-weight:800;
            }
        }
        &:hover {
            .video-image {
                .play-btn {
                    // background-color:rgba(0,0,0,0.35);
                    filter:opacity(0.8);
                }
            }
            .video-overlay {
                background-color:rgba(0,0,0,0.7);
            }
        }
    }
}


.horror-detail {
  background-color:#fff;
  box-shadow:$base-shad;
  .horror-img {
    background:#222;
    max-height:900px;
    overflow:hidden;
    img {
      object-fit:contain;
      max-height:inherit;
      display:block;
      margin:0 auto;
      width:100%;
      height:calc(100vh - 192px);
      min-height:480px;
    }
  }
  .horror-info {
    // padding:15px;
    border-bottom:1px solid #ddd;
    padding-bottom:12px;
    margin-bottom:24px;
    .horror-rating {
      margin-top:0px;
      .full-star, .empty-star {
        width:15%;
        max-width:28px;
        height:48px;
        display:inline-block;
        background-repeat:no-repeat;
        background-position:center center;
        background-size:contain;
        overflow:hidden;
      }
      .full-star {
        background-image:url('../img/full-star.svg');
      }
      .empty-star {
        background-image:url('../img/emtpy-star.svg');
      }
    }
    .horror-prof {
      display:flex;
      flex-direction:row;
      flex:2;
      padding:0;
      .prof {
        img {
          width:48px;
          height:48px;
          margin-right:12px;
        }
      }
      .details {
        text-align:left;
        .name {
          font-size:16px;
          margin-bottom: 4px;
          margin-top:4px;
        }
        .loc {
          font-size:14px;
          color:#999;
        }
      }
    }
    .rating {
      padding:0;
      .label {
        font-size:12px;
      }
    }
    .desc {
      padding:0;
    }
    .description {
      text-align:  left;
    }

  }
  .horror-submit-rating {

    .submit-rating {
      margin-top: 8px;
      .full-star, .empty-star {
        width: 15%;
        max-width: 58px;
        height: 48px;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        overflow: hidden;
      }
      .full-star {
        background-image: url('../img/full-star.svg');
      }
      .empty-star {
        background-image: url('../img/emtpy-star.svg');
      }
      .small-star {
        // &:nth-child(3):hover + &:nth-child(2) {
        //     background-image: url('../img/full-star.svg');
        //     opacity:0.5;
        // }
        // &:hover  {
        //     background-image: url('../img/full-star.svg');
        //     opacity:0.5;
        // }
        cursor:pointer;
      }
      .small-star:hover {
        background-image: url('../img/full-star.svg');
        opacity:0.5;
      }

    }
    .faces {
      max-width: 300px;
      margin: auto;
      .notsobad, .horrendous {
        width: 68px;
        height: 68px;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        overflow: hidden;
      }
      .notsobad {
        float: left;
        background-image: url('../img/notsobad.png');
      }
      .horrendous {
        float: right;
        background-image: url('../img/horrendous.png');
      }
    }
    .loc {
      font-size:16px;
      color:$light-grey;
      margin-bottom:12px;
    }
    .label {

    }
    .title-text {
      font-size:16px;
      margin-bottom:12px;
    }

  }




  .ga-button {
    margin: 10px 0px 24px 0px;

  }
}





.talent-detail {
  background-color:#fff;
  box-shadow:$base-shad;
  .talent-img {
    background:#fff;
    max-height:900px;
    overflow:hidden;
    img {
      object-fit:contain;
      max-height:inherit;
      display:block;
      margin:0 auto;
      width:100%;
      height:calc(100vh - 192px);
      min-height:480px;
    }
  }
  .talent-info {
    // padding:15px;
    border-bottom:1px solid #ddd;
    padding-bottom:12px;
    margin-bottom:24px;
    .talent-rating {
      margin-top:0px;
      .full-thumb, .empty-thumb {
        width:15%;
        max-width:28px;
        height:48px;
        display:inline-block;
        background-repeat:no-repeat;
        background-position:center center;
        background-size:contain;
        overflow:hidden;
      }
      .full-thumb {
        background-image:url('../img/full-thumb.svg');
      }
      .empty-thumb {
        background-image:url('../img/empty-thumb.svg');
      }
    }
    .talent-prof {
      display:flex;
      flex-direction:row;
      flex:2;
      padding:0;
      .prof {
        img {
          width:48px;
          height:48px;
          margin-right:12px;
        }
      }
      .details {
        text-align:left;
        .name {
          font-size:16px;
          margin-bottom: 4px;
          margin-top:4px;
        }
        .loc {
          font-size:14px;
          color:#999;
        }
      }
    }
    .rating {
      padding:0;
      .label {
        font-size:12px;
      }
    }
    .desc {
      padding:0;
    }
    .description {
      text-align:  left;
    }

  }
  .talent-submit-rating {

    .submit-rating {
      margin-top: 8px;
      .full-thumb, .empty-thumb {
        width: 15%;
        max-width: 58px;
        height: 48px;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        overflow: hidden;
      }
      .full-thumb {
        background-image: url('../img/full-thumb.svg');
      }
      .empty-thumb {
        background-image: url('../img/empty-thumb.svg');
      }
      .small-thumb {
        cursor:pointer;
      }
      .small-thumb:hover {
        background-image: url('../img/full-thumb.svg');
        opacity:0.5;
      }

    }
    .faces {
      max-width: 300px;
      margin: auto;
      .notsobad, .horrendous {
        width: 68px;
        height: 68px;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        overflow: hidden;
      }
      .notsobad {
        float: left;
        background-image: url('../img/notsobad.png');
      }
      .horrendous {
        float: right;
        background-image: url('../img/horrendous.png');
      }
    }
    .loc {
      font-size:16px;
      color:$light-grey;
      margin-bottom:12px;
    }
    .label {

    }
    .title-text {
      font-size:16px;
      margin-bottom:12px;
    }

  }




  .ga-button {
    margin: 10px 0px 24px 0px;

  }
}










.end-mes {
    color:#888;
}
.sub-list {
    list-style:none;
}

.horrorposts-header {
  .ga-button {
    float: right;

  }
}

.talentposts-header {
  .ga-button {
    float: right;

  }
}

.new-horror {

}



.center-cropped {
    width: 170px;
    height: 115px;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
}

.center-cropped img {
    min-height: 100%;
    min-width: 100%;
}

.main-wrap {
    .channel {
        border-bottom:1px solid #bbb;
        margin-bottom:12px;
        background-color:#fff;
        border-radius:4px;
        padding:15px 0 15px 0px;
        .channel-title {
            text-align:left;
            font-weight:700;
            font-family:$head-font;
            font-size:20px;
            color:#000;
            padding-bottom:12px;
            padding-left:27px;
            margin-bottom:12px;
            border-bottom:1px solid #ccc;
        }
        .channel-wrap {
            width:100%;
            overflow-x:scroll;
            overflow-y:hidden;
            .channel-cell {
                width:9000px;
                .video-cell {
                    display:inline-block;
                    width:200px;
                    height:168px;
                    background-color:$dark-grey;
                    vertical-align:top;
                    color:#fff;
                    margin:12px;
                    float:left;
                    box-shadow:$base-shad;
                    font-size:0;
                    cursor:pointer;
                    position:relative;
                    &:first-child {
                        margin-left:27px;
                    }
                    .vid-img {
                        position:relative;
                        img {
                            width:100%;
                            height:112px;
                            object-fit:cover;
                        }
                        &:after {
                            content:"";
                            background-image:url('../img/play.png');
                            position:absolute;
                            width:200px;
                            height:112px;
                            top:0;
                            left:0;
                            background-size:cover;
                            background-position:top center;
                            // opacity:0.5;
                        }
                    }
                    .vid-title {
                        font-size:0;
                        overflow:hidden;
                        max-height:54px;
                        p {
                            margin:0;
                            font-size:14px;
                            font-weight:700;
                            text-align:left;
                            padding:8px 12px;
                            // text-decoration:underline;
                        }
                    }
                    &:hover {
                        .vid-title {
                            p {
                                text-decoration:underline;
                            }
                        }
                        .vid-img {
                            &:after {
                                background-position:bottom center;
                            }
                        }
                    }
                }
            }
        }
    }
    &.back-wrap {
        text-align:left;
        margin-bottom:12px;
    }
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.comments-wrap {
    .post-detail, .replies {
        display:inline-block;
        width:100%;
    }
    .post-detail {

    }
    .replies {
        .reply-wrap {
            display:inline-block;
            width:100%;
        }

    }
}



.loginMain {
    min-height:100vh;
    // padding:12px;
    margin:0;
    border:0;
    box-sizing:border-box;
    background:$main-blue;
    > h1 {
        color:#fff;
    }
    .login-box {
        background-color:#fff;
        width:100%;
        max-width:360px;
        margin:0 auto;
        box-shadow:$base-shad;
        h2 {
            padding:12px 16px;
            border-bottom:1px solid #ddd;
        }
        .login-label {
            font-weight:700;
            text-align:left;
            display:block;
            margin-bottom:4px;
            color:#888;
            font-size:10px;
        }
        .login-field {
            display:block;
            width:100%;
            max-width:100%;
            font-size:16px;
            font-family:$body-font;
            padding:10px 16px;
            box-sizing:border-box;
            border:1px solid #ececec;
            background-color:#f8f8f8;
            color:#000;
        }
        .ga-button {
            background-color:$main-blue;
            margin-right:0;
            margin-bottom:12px;
        }
        .small-link {
            color:$main-blue;
            font-size:12px;
            cursor:pointer;
            display:inline-block;
            margin-bottom:12px;
            &:hover {
                text-decoration:underline;
            }
        }
    }
    .reg-box, .sub-box {
        background-color:#fff;
        display:block;
        max-width:640px;
        margin:0 auto;
        box-shadow:$base-shad;
        border-radius:5px;
        margin-bottom:24px;
        padding-bottom:12px;
        h1 {
            padding:12px 16px;
            border-bottom:1px solid #e5e5e5;
            background-color:#e5e5e5;
            border-radius:5px 5px 0 0;
        }
        .reg-label {
            font-weight:700;
            text-align:left;
            display:block;
            margin-bottom:4px;
            color:#000;
            font-size:16px;
            font-family: $head-font;
            &.tick-text {
                .reg-field {
                    width:auto;
                    display:inline-block;  
                }
            }
        }
        .reg-field {
            display:block;
            width:100%;
            max-width:100%;
            font-size:16px;
            font-family:$body-font;
            padding:10px 16px;
            box-sizing:border-box;
            border:1px solid #c7c7c7;
            background-color:#fff;
            color:#000;
            border-radius: 5px;
        }
        .reg-select {
            display:block;
            font-size:16px;
            font-family:$body-font;
            padding:10px 16px;
            box-sizing:border-box;
            margin:0;
            cursor:pointer;
            border:1px solid #ececec;
            border-radius:0;
            width:100%;
            max-width: 100%;
            -moz-appearance:none;
            -webkit-appearance:none;
            appearance:none;
            background-color:#f8f8f8;
            background-image:url('../img/drop-arrow.svg');
            background-repeat: no-repeat;
            background-position:right .7em top 50%, 0 0;
            background-size: .65em auto, 100%;
        }
    }
    .sub-box {
        max-width:none;
    }
    .main-wrap {
        .cell.header {
            text-align:center;
        }
    }
}

.settings-section {
    .medium-4 {
        .list-item {
            height:240px;
            background-position:center 45%;
            background-size: 27% auto;
            position:relative;
            margin-bottom:0;
            &.list-arrow {
                background-image:none;
            }
            .section-title {
                text-align:center;
                width:100%;
                padding:0 0 22px 0;
                position:absolute;
                bottom:0;
                left:0;
                font-size:18px;
                .info {
                    display:block;
                    font-size:14px;
                    color:#777;
                }
            }
            
        }
        &.email {
            .list-item {
                background-image:url('../img/mail.svg');
            }
        }
        &.password {
            .list-item {
                background-size:25% auto;
                background-position:center 34%;
                background-image:url('../img/lock.svg');
            }
        }
        &.profile {
            .list-item {
                background-size:29% auto;
                background-position:center 40%;
                background-image:url('../img/profile-pic.svg');
            }
        }
      &.sub {
        .list-item {
          background-size:29% auto;
          background-position:center 40%;
          background-image:url('../img/subscription.svg');
        }
      }
      &.terms {
        .list-item {
          background-size:29% auto;
          background-position:center 40%;
          background-image:url('../img/terms.svg');
        }
      }
        &.logout {
            .list-item {
                background-size:24% auto;
                background-position:center 40%;
                background-image:url('../img/logout.svg');
            }
        }
    }
}
.info-item {
    background-color:#fff;
    padding:15px;
    text-align:left;
    box-shadow:$base-shad;
    height:240px;
    box-sizing:border-box;
    .prof-title {
        font-size:18px;
        display:block;
        padding-bottom:24px;
    }
    .prof-sel-title {
        display:block;
        font-size:14px;
        position:relative;
        cursor:pointer;
        -webkit-user-select:none;
        -moz-user-select:none;
        -ms-user-select:none;
        user-select:none;
        height:25px;
        margin-bottom:8px;
        .checkmark {
            position:absolute;
            top:0;
            right:0;
            height:25px;
            width:25px;
            background-color:#ccc;
            &:after {
                content:"";
                position:absolute;
                display:none;
                left: 9px;
                top: 5px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
        input {
            position:absolute;
            opacity:0;
            cursor:pointer;
            height:0;
            width:0;
            &:checked {
                ~ .checkmark {
                    background-color:$main-blue;
                    &:after {
                        display:block;
                    }
                }
            }
        }
        &:hover {
            input ~ .checkmark {
                background-color:$light-grey;
            }
            input:checked ~ .checkmark {
                background-color:#3664c2;
            }
        }
    }
}
.nmp {
    color:#888;
    font-family:$body-font;
    display:block;
    padding:24px 0px;
}


@media screen and (max-width: 639px) {
    .square-white.competition {
        background-color:#fff;
        background:#fff;
        display:block;
    }
}

.break-horror {
    font-size:0;
    .horror-cell {
        padding:0;
        box-shadow:none;
        .horror-overlay {
            top:auto;
            left:12px;
            right:12px;
            bottom:12px;
            transition:background 0.2s ease;
            .horror-icon {
                height:30px;
                width:30px;
                background-image:url('../img/menu_item_horror_show.svg');
                background-size:contain;
                position:absolute;
            }
            .horror-details {
                padding-left:36px;
            }
            .horror-rating {
                max-width:140px;
                margin-top:0;
            }
            div {
                text-align:left;
                &.loc {
                    font-size:14px;
                    color:#fff;
                    opacity:0.8;
                }
            }
        }
    }
    &:hover {
        .horror-cell {
            .horror-overlay {
                background:rgba(0,0,0,0.6);
            }
        }
    }
}
.break-fault {
    font-size:0;
    padding:0;
    .fault-wrap {
        border:2px solid $main-yellow;
        padding:10px;
    }
    .fault-details {
        font-size:16px;
        text-align:left;
        padding-left:36px;
        .sub-text {
            font-size:12px;
            color:$dark-grey;
        }
        .title {

        }
    }
    .pdf-link {
        font-size:16px;
        margin-bottom:0;
    }

}
.break-post {

}

.ga-back {
    float:left;
    margin-bottom:12px;
    // background-color:$main-yellow;
    // color:$dark-grey;
    margin-left:12px;
    // &:hover {
    //     background-color:#e19106;
    // }
}
.small-12 > .ga-back {
    margin-left:0;
    margin-bottom:0;
}

.ppm {
    .square-white {
        position:relative;
        .sponsor {
            position:absolute;
            top:0px;
            right:0px;
            background:#47535f;
            color:#fff;
            font-size:12px;
            padding:4px 8px;
            font-family:$body-font;
        }
        img {
            width:100%;
            max-width:600px;
        }
        .title-text {
            padding:0 40px;
        }
        input {
            display: block;
            font-size: 16px;
            font-family: "Open Sans",sans-serif;
            padding: 10px 16px;
            width: 100%;
            max-width: 100%;
            box-sizing: border-box;
        }
    }
}

.fault-post-cell {
    padding:0;
    .fault-wrap {
        padding:10px;
        border:2px solid $main-yellow;
    }
}
.image-thumb {
    img {
        width:256px;
        height:256px;
        object-fit: cover;
        // float:right;
    }
}
.main-wrap {
    .cell.prof-upload {
        label {
            display:block;
        }
        .ga-button {
            // display:block;
            margin-right:0;
            margin-top:12px;
        }
    }
}
.prof-upload, .image-thumb {
    .create-label {
        margin-bottom:12px !important;
    }
}












.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1;
}
.swiper-container-no-flexbox .swiper-slide {
  float: left;
}
.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}
.swiper-container-multirow > .swiper-wrapper {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}
.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  // width: 100%;
  height: 100%;
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  &:first-child {
    padding-left:2px;
  }
  &:last-child {
    padding-right:30px;
  }
}
.swiper-slide-invisible-blank {
  visibility: hidden;
}
/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transition-property: height, -webkit-transform;
  transition-property: height, -webkit-transform;
  -o-transition-property: transform, height;
  transition-property: transform, height;
  transition-property: transform, height, -webkit-transform;
}
/* 3D Effects */
.swiper-container-3d {
  -webkit-perspective: 1200px;
  perspective: 1200px;
}
.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}
.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
/* IE10 Windows Phone 8 Fixes */
.swiper-container-wp8-horizontal,
.swiper-container-wp8-horizontal > .swiper-wrapper {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.swiper-container-wp8-vertical,
.swiper-container-wp8-vertical > .swiper-wrapper {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
}
.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-lock {
  display: none;
}
.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms opacity;
  -o-transition: 300ms opacity;
  transition: 300ms opacity;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}
/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%;
}
/* Bullets */
.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
  position: relative;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
}
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}
button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff;
}
.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0);
}
.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 6px 0;
  display: block;
}
.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 8px;
}
.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  display: inline-block;
  -webkit-transition: 200ms top, 200ms -webkit-transform;
  transition: 200ms top, 200ms -webkit-transform;
  -o-transition: 200ms transform, 200ms top;
  transition: 200ms transform, 200ms top;
  transition: 200ms transform, 200ms top, 200ms -webkit-transform;
}
.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px;
}
.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  white-space: nowrap;
}
.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: 200ms left, 200ms -webkit-transform;
  transition: 200ms left, 200ms -webkit-transform;
  -o-transition: 200ms transform, 200ms left;
  transition: 200ms transform, 200ms left;
  transition: 200ms transform, 200ms left, 200ms -webkit-transform;
}
.swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: 200ms right, 200ms -webkit-transform;
  transition: 200ms right, 200ms -webkit-transform;
  -o-transition: 200ms transform, 200ms right;
  transition: 200ms transform, 200ms right;
  transition: 200ms transform, 200ms right, 200ms -webkit-transform;
}
/* Progress */
.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
}
.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
}
.swiper-container-horizontal > .swiper-pagination-progressbar,
.swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}
.swiper-container-vertical > .swiper-pagination-progressbar,
.swiper-container-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}
.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #ffffff;
}
.swiper-pagination-progressbar.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.25);
}
.swiper-pagination-progressbar.swiper-pagination-white .swiper-pagination-progressbar-fill {
  background: #ffffff;
}
.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000000;
}
.swiper-pagination-progressbar.swiper-pagination-black {
  background: rgba(0, 0, 0, 0.25);
}
.swiper-pagination-progressbar.swiper-pagination-black .swiper-pagination-progressbar-fill {
  background: #000000;
}
.swiper-pagination-lock {
  display: none;
}
/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}
.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}
.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}
.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}
.swiper-scrollbar-cursor-drag {
  cursor: move;
}
.swiper-scrollbar-lock {
  display: none;
}
.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}
.swiper-zoom-container > img,
.swiper-zoom-container > svg,
.swiper-zoom-container > canvas {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.swiper-slide-zoomed {
  cursor: move;
}
/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}
.swiper-lazy-preloader:after {
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}
.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}
@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}
.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}
// .swiper-slide-active {
//     background:#000;
// }
.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-cube {
  overflow: visible;
}
.swiper-container-cube .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}
.swiper-container-cube .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}
.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}
.swiper-container-flip {
  overflow: visible;
}
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.swiper-container-coverflow .swiper-wrapper {
  /* Windows 8 IE 10 fix */
  -ms-perspective: 1200px;
}
.swiper-button-next, .swiper-button-prev {
    background-color:rgba(85,130,222,0.75);
    background-image:url('../img/menu-arrow.svg');
    background-size:auto 24%;
    cursor:pointer;
    height:43px;
    width:24px;
    transition:opacity 0.2s ease;
    &.swiper-button-disabled {
        cursor:default;
        opacity:0.2;
    }
}
.swiper-button-next {
    right:0;
}
.swiper-button-prev {
    transform:rotate(180deg);
    left:0;
}
select {
    border-color:#C7C7C7;
    border-radius:5px;
    font-family:$body-font;
    font-size:18px;
  padding: 10px 20px; // Adds internal spacing for text
  appearance: none; // Removes default arrow (for most browsers)
  -webkit-appearance: none; // For Safari
  -moz-appearance: none; // For Firefox
  background: white url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path d="M7 10l5 5 5-5z" fill="%23666"/></svg>') no-repeat right 10px center;
  background-size: 18px;
}
.reg-select {
  padding:6px 12px;
  margin-left:8px;
}
.inv-select {
  padding:10px 12px;
  width: 100%;
}
.react-calendar {
    border:1px solid #c7c7c7;
    border-radius:5px;
    font-family:$body-font;
    width:auto;
}
.create-box {
    .small-12 {
        // padding-left:0;
    }
}
.pricing {
    .what-you-get {
        h2 {
            text-align:center;
            font-family:$head-font;
            margin-top:48px;
            margin-bottom:24px;
            color:$dark-grey;
            font-size:36px;
            font-weight:800;
            border:0;
        }
        p {
            text-align:center;
        }
        .features {
            display:flex;
            flex-direction:row;
            flex:3;
            flex-flow:row wrap;
            // flex-grow:1;
            .feature {
                margin:0 auto;
                text-align:left;
                .tick-wrap {
                    display:flex;
                    flex-direction: row;
                    flex:2;
                    img {
                        width:18px;
                        height:14px;
                        display:inline-block;
                        margin-right:24px;
                        // margin-left:36px;
                        margin-top:6px;
                    }
                    .tick-text {
                        margin-bottom:36px;
                        width:calc(100% - 42px);
                        .tick-title {
                            font-family:$body-font;
                            display:block;
                            font-weight:800;
                        }
                        .tick-note {
                            font-family:$body-font;
                            display:block;
                            font-weight:400;
                        }
                    }
                }
            }
        }
    }

    .what-its-on {
        h2 {
            font-family:$head-font;
            color:$dark-grey;
            font-size:24px;
            text-align:center;
            margin-top:48px;
            margin-bottom:24px;
            font-size:36px;
            border:0;
        }
        img {
            width:100%;
            max-width:600px;
            display:block;
            margin:0 auto;
        }
    }

    .how-much {
        h2 {
            text-align:center;
            font-family:$head-font;
            margin-top:48px;
            margin-bottom:24px;
            color:$dark-grey;
            font-size:36px;
            font-weight:800;
            border:0;
        }
        p {
            text-align:center;
        }
        .medium-6 {
            padding:12px;
            .box-frame {
                border:2px solid #363636;
                border-radius:10px;
                
                margin-bottom:12px;
                h2 {
                    text-align:center;
                    color:$dark-grey;
                    font-family:$head-font;
                    font-size:36px;
                    margin-top:16px;
                }
                .price {
                    font-size:54px;
                    font-family:$body-font;
                    text-align:center;
                    color:$dark-grey;
                    margin-top:20px;
                    margin-bottom:-8px;
                    span {
                        font-size:30px;
                    }
                    &:after {
                        content:'.00';
                        font-size:30px;
                    }
                }
                .time {
                    font-size:18px;
                    font-family:$head-font;
                    text-align:center;
                    display:block;
                    width:100%;
                    color:$dark-grey;
                }
                .note {
                    font-size:18px;
                    font-family:$body-font;
                    color:#707070; 
                    text-align:center;
                    display:block;
                    width:100%;
                }
                .btn-wrap {
                    width:100%;
                    display:block;
                    padding:0 24px;
                    box-sizing:border-box;
                    .ga-button {
                        width:100%;
                        margin-top:44px;
                        margin-right:0;
                        box-sizing:border-box;
                        margin-bottom:12px;
                        font-weight:800;
                        font-size:18px;
                        text-decoration:none;
                        .trial-note {
                            display:block;
                            text-align:center;
                            font-weight:400;
                            font-size:14px;
                            margin-top:6px;
                        }
                    }
                }
                &.monthly {
                    .btn-wrap {
                        .ga-button {
                            background-color:#ffb700;
                            color:$dark-grey;
                            &:hover {
                                background-color:#fd9500;
                            }
                        }
                    }
                }
                &.yearly {
                    background-color:#e4ecfa;
                    border:2px solid #5d687d;
                    .btn-wrap {
                        .ga-button {
                            // background-color:#76d984;
                            color:#fff;
                            margin-top:20px;
                        }
                    }
                }
            }
        }
        .stripe {
            display:flex;
            margin:0 auto;
            flex-direction:row;
            flex:4;
            width:100%;
            justify-content:center;
            margin-top:12px;
            margin-bottom:24px;
            img {
                height:40px;
                display:inline-block;
                margin:0 6px;
            }
        }
    }
}
.sub-info {
    border:2px solid $light-grey;
    border-radius:8px;
    padding:12px;
    font-size:20px;
    display:inline-block;
    .subdate {
        display:block;
        font-size:30px;
        font-weight:800;
        margin-top:6px;
    }
}
.partner-list {
    display:flex;
    flex:2;
    flex-direction:row;
    flex-flow:row wrap;
    .cell {
        margin-bottom:30px;
        .square-white {
            height:100%;
        }
    }
}
.mdframe {
    max-width:640px;
    margin:0 auto;
}
.refpdfs {
    .list-item {
        background-image: url('../img/book1.svg');
        background-repeat:no-repeat;
        background-position:12px center;
        background-size:auto 28px;
        padding-left:48px;
    }
}
.contactlist {
    .list-item {
        background-image: url('../img/phone1.svg');
        background-repeat: no-repeat;
        background-position: 12px center;
        background-size: auto 26px;
        padding-left:48px;
    }
}
.returnbox {
    margin-top:12px;
    margin-bottom:12px;
    .ga-button {
        margin-right:0;
        text-decoration: none;
    }
}
.deals-list {
    .list-item {
        img {
            display:block;
            margin:0 auto;
        }
    }
}

.postcode-lookup {
    .cell {
        padding-left:0px;
        padding-right:0px;
    }
    .reg-field {
        border:1px solid #c7c7c7;
        border-radius:5px;
        padding:8px;
        font-size: 16px;
        color:#000;
        font-family: $body-font;
        display:block;
        width:100%;
        box-sizing: border-box;
    }
    .ga-button {
        margin-right:0;
        display:block;
        width:100%;
        box-sizing: border-box;
        text-align:center;
    }
}

.custlist {

  text-align: left;

  .bold-text {
    font-weight:800;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
  }
  .padit {
    margin: 0 20px;
  }
  .content {
    background: white;
  }

  .search {
    display:block;
    font-size:16px;
    font-family:$body-font;
    padding:10px 16px;
    width:100%;
    max-width:100%;
    box-sizing:border-box;
    border-radius:5px;
    border:1px solid #c7c7c7;
  }
}

.content-body {
    box-shadow: none;
}



.formlist {

  text-align: left;
  .bold-text {
    font-weight:800;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
  }
  .formHead {
    background: $light-grey;
    color: white;
    padding: 12px;
    margin-top:0;
    &:hover {
        cursor:pointer;
    }
  }
  .padit {
    margin: 20px;
  }
  .content {
    background: white;
  }

  .search {
    display:block;
    font-size:16px;
    font-family:$body-font;
    padding:10px 16px;
    width:100%;
    max-width:100%;
    box-sizing:border-box;
  }
  .list-arrow {
    background-image: url("../img/disc1.png");
    background-repeat: no-repeat;
    background-size:15px 28px;
    background-position:96% center;
  }
}




.custom-date-range {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space out elements */
  width: 100%; /* Ensure it spans full width */
}

.custom-date-range .ga-button {
  flex: 1; /* Allow buttons to take equal space */
  text-align: center; /* Center button text */
  margin: 6px;
}

.to-text {
  flex: 0; /* Keeps "to" from stretching */
  margin: 0 10px; /* Adjust spacing */
  font-weight: bold;
}

h1.head-title.office {
    margin:6px 0;
}
.invoice-label-head {
    margin:0;
    margin-bottom:6px;
}
.edit-field {
    border:1px solid #c7c7c7;
    border-radius:5px;
    font-family: $body-font;
    font-size:16px;
    padding:6px;
}
.invoice-total {
    margin-bottom:0;
    margin-top:24px;
}
.main-wrap .cell.no-bot {
    padding-bottom:0;
}
.main-wrap .cell.no-side-pad {
    padding-left:0;
}



.name-highlight {
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 3px;
  padding-bottom: 4px;
  border-radius: 5px;
  display: inline-block;
}

.cal-events{
  .item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #e5e5e5;
    color: #000;
    font-weight:800;
    padding: 8px 16px 8px 16px;
    font-family:$head-font;
  }
  .ga-button {
    margin-top: 20px;
  }

  .event-container {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .event-time {
    flex: 0 0 80px;
    text-align: center;
   }

  .event-details {
    flex: 1; /* Takes up remaining space */
  }
}



.invoice {
  text-align: left;
  .bold-text {
    font-weight:800;
    display:inline-block;
    // margin-right:8px;
  }
  // .head-title {
  //   margin-left:12px;
  // }
  .edit-button {
    display: inline-block;
    width: 22px;
    height: 22px;
    padding:0px 4px;
    margin-right: 0;
    background-repeat: no-repeat;
    background-size: 22px;
    background-position: center center;
    background-image: url("../img/editwhite.png");
    cursor: pointer;
  }
  .dark-button {

    background-image: url("../img/edit.png");
  }


  .item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #c7c7c7;
    color: #000;
    font-weight:800;
    padding: 8px 16px 8px 16px;
    font-family:$head-font;
  }
  .item-text {
    background: #e5e5e5;
    padding: 8px 16px 8px 16px;
    color: black;
  }
  .small {
    font-size:12px;
  }
  .line {
    border: 1px solid #c7c7c7;
  }
  .unpaid {
    background: $button-red;
    color: white;
    font-weight:800;

  }
  .buttons {
    text-align: center;
    border-top:1px solid #c7c7c7;
  }
  .paid {
    background: $button-green;
    color: white;
    font-weight:800;

  }
  .inv-total{
    text-align: center;
    font-size: 24px;
    font-weight:800;
    font-family:$head-font;
    padding-bottom: 12px;
  }
}

.react-datepicker-wrapper {
    width:100%;
}
.quote {
  text-align: left;
  .bold-text {
    font-weight:800;
  }
  .edit-button {
    display: inline-block;
    width: 22px;
    height: 22px;
    padding:0px 4px;
    margin-right: 0;
    background-repeat: no-repeat;
    background-size: 22px;
    background-position: center center;
    // vertical-align:-3px;
    background-image: url("../img/editwhite.png");
    cursor: pointer;
  }
  .dark-button {

    background-image: url("../img/edit.png");
  }


  .grid-container {
    display: flex;
    flex-wrap: wrap;
  }

  .responsive-img {
    max-width: 90%;
    height: auto;
    display: block;
  }

  .item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #c7c7c7;
    color: #000;
    font-weight:800;
    padding: 8px 16px 8px 16px;
    font-family:$head-font;
  }
  .item-text {
    background: #e5e5e5;
    padding: 8px 16px 8px 16px;
    color: black;
  }
  .small {
    font-size:12px;
  }
  .line {
    border-top: 1px solid $main-grey;
  }
  .expired {
    background: $button-red;
    color: white;
    font-weight:800;

  }
  .buttons {
    text-align: center;

  }
  .valid {
    background: #e5e5e5;
    // color: $button-green;
    font-weight:800;
    h3 {
        // background:$button-green;
        padding:12px;
        margin:0;
        text-align:left;
        padding-left:0px;
    }
    .validity {
      color: black;
      font-weight:normal;
    }
  }
}


.reminders {
  text-align: left;
  .bold-text {
    font-weight:800;
  }
  .head-title {
    margin-left:12px;
  }
  .warn {
    text-align: center;
    background: red;
    color: white;
    padding: 20px;
  }
  .padit {
    margin: 20px;
  }
  .content {
    background: white;
  }
  .cell {
    background: white;
  }
  .titleRow {
    background: #e5e5e5;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cog {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    vertical-align:-3px;
    background-image: url("../img/gear.png");
  }

  .item-img {
    margin-right: 90px;
    text-align: center;
    width: 111px;
   }
  .upcoming {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../img/reminders_upcoming.png");
  }
  .sent {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../img/reminders_sent.png");
  }
  .read {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../img/reminders_read.png");
  }
  .responded {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../img/reminders_responded.png");
  }
  .reminder-item {
     display: flex;
    justify-content: space-between;
    align-items: center;
    background-color:#fff;
    padding-left:15px;
    padding-bottom:15px;
    padding-top:15px;
    cursor:pointer;
    margin-bottom:12px;
    text-align:left;
    background-image: url("../img/three_dots.png");
    background-repeat: no-repeat;
    background-size:5px 28px;
    background-position:97% 44%;
    transition:all 0.2s ease;
    box-shadow: $base-shad;
    box-sizing:border-box;
    border:1px solid #e5e5e5;
    > * {
      cursor:pointer;
    }

    &:hover {
      background-color:#eee;
      box-shadow:$hover-shad;
      .section-title {
        padding-left:48px;
      }
      .news-title {
        text-decoration:underline;
      }
    }
    h2 {
      margin-bottom: 5px;
      margin-left: 0px;
      margin-top: 0px;
    }
    h4 {
      margin-bottom: 5px;
      margin-left: 0px;
      margin-top: 0px;

    }
    div {
      margin-bottom: 5px;
      margin-left: 0px;
      margin-top: 0px;
    }
  }


}



.modal {
   position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  h2 {
    padding-top:0;
    margin-top:0;
  }
}
.main-wrap .cell.no-side-pad {
    padding-left:0;
    padding-right:0;
}
.modal-button {
    width:100%;
    box-sizing:border-box;
    display:block;
}
 .modal-content {
   background-color: #fefefe;
   padding: 20px;
   width: 400px;
   border-radius: 5px;
   position: relative;
   text-align: left;
   max-height: 80vh;
   overflow-y: auto;


   .bold-text{
     font-weight: 800;
   }
   .edit-field {
     display:block;
     width:100%;
     max-width:100%;
     height:40px;
     margin-top:10px;
     font-size:16px;
     font-family:$body-font;
     padding:10px 16px;
     box-sizing:border-box;
     resize:none;
     border:1px solid #c7c7c7;
     border-radius:5px;
   }


   .centered {
     text-align: center;

   }

   .highlighted {
     background: $main-grey;
   }

   .faded {
     opacity: 0.5;
   }
   .history {
     display: flex;
     justify-content: space-between;
     align-items: center;
     margin: 0px 20px 20px 20px;

     .history-label {
       width: 100%;

       .bold-text {
         font-weight:800;

       }
     }



     .upcoming {
       display: inline-block;
       margin: 8px 16px 4px 12px;
       width: 32px;
       height: 32px;
       background-repeat: no-repeat;
       background-size: contain;
       background-image: url("../img/reminders_upcoming.png");
     }



     .sent {
       display: inline-block;
       margin: 8px 16px 4px 12px;
       width: 32px;
       height: 32px;
       background-repeat: no-repeat;
       background-size: contain;
       background-image: url("../img/reminders_sent.png");
     }

     .read {
       display: inline-block;
       margin: 8px 16px 4px 12px;
       width: 32px;
       height: 32px;
       background-repeat: no-repeat;
       background-size: contain;
       background-image: url("../img/reminders_read.png");
     }

     .responded {
       display: inline-block;
       margin: 8px 16px 4px 12px;
       width: 32px;
       height: 32px;
       background-repeat: no-repeat;
       background-size: contain;
       background-image: url("../img/reminders_responded.png");
     }
   }



   .weeks {
     display:block;
     font-size:16px;
     font-family:$body-font;
     padding:10px 16px;
     box-sizing:border-box;
     margin:0;
     cursor:pointer;
     border:1px solid #c7c7c7;
     border-radius:0;
     width:100%;
     max-width: 100%;
     -moz-appearance:none;
     -webkit-appearance:none;
     appearance:none;
     background-color:#fff;
     background-image:url('../img/drop-arrow.svg');
     background-repeat: no-repeat;
     background-position:right .7em top 50%, 0 0;
     background-size: .65em auto, 100%;
     border-radius:5px;
   }

   .msg {
     display:block;
     width:100%;
     max-width:100%;
     height:169px;
     font-size:16px;
     font-family:$body-font;
     padding:10px 16px;
     box-sizing:border-box;
     resize:none;
     border:1px solid #c7c7c7;
     border-radius:5px;
   }
   .form-title {
    font-family:$head-font;
    font-weight:600;
    margin:6px 0;
   }


   .autoemails {
     display:block;
     position:relative;
     cursor:pointer;
     -webkit-user-select:none;
     -moz-user-select:none;
     -ms-user-select:none;
     user-select:none;
     height:25px;
     margin-bottom:8px;
     .checkmark {
       position:absolute;
       top:10px;
       right:0;
       height:25px;
       width:25px;
       background-color:#ccc;
       &:after {
         content:"";
         position:absolute;
         display:none;
         left: 9px;
         top: 5px;
         width: 5px;
         height: 10px;
         border: solid white;
         border-width: 0 3px 3px 0;
         -webkit-transform: rotate(45deg);
         -ms-transform: rotate(45deg);
         transform: rotate(45deg);
       }
     }
     input {
       position:absolute;
       opacity:0;
       cursor:pointer;
       height:0;
       width:0;
       &:checked {
         ~ .checkmark {
           background-color:$main-blue;
           &:after {
             display:block;
           }
         }
       }
     }
     &:hover {
       input ~ .checkmark {
         background-color:$light-grey;
       }
       input:checked ~ .checkmark {
         background-color:#3664c2;
       }
     }
   }
}
.form-title {
    font-family:$head-font;
    font-weight:600;
    margin:6px 0;
   }

.date-range {
    padding:12px;
    background-color:#e5e5e5;
    font-weight:800;
    font-family:$head-font;
}



.date-range {
  padding:12px;
  background-color:#e5e5e5;
  font-weight:800;
  font-family:$head-font;
  display: flex;
  justify-content: space-between;
  align-items: center;

}



.invoice-prev,
.invoice-next {
  display: inline-block;
  width: 1em;
  height: 1em;
  background-image: url("../img/drop-arrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.invoice-prev {
  transform: rotate(90deg);
}

.invoice-next {
  transform: rotate(-90deg);
}


.grey-head {
  padding:12px;
  background-color:#e5e5e5;
}

.red-head {
  padding:12px;
  background-color:$button-red;
}




@media screen and (min-width: 830px) {

    .rounded-main {
        margin: 0px auto;
        // width: 80%;
    }

    .grey-main {

        margin: 0px auto;
        width: 80%;
    }

}

@media screen and (min-width: 1030px) {

    .rounded-main {
        // width: 60%;
    }


    .grey-main {

        margin: 0px auto;
        width: 60%;
    }
}
